import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 248.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,248.000000) scale(0.100000,-0.100000)">
   
<path d="M943 1688 c-15 -24 -48 -77 -73 -118 -26 -41 -97 -155 -159 -253 -61
-98 -111 -185 -111 -192 0 -17 111 -30 177 -21 59 8 254 65 268 78 6 6 -35 8
-113 6 -67 -2 -122 -2 -122 -2 0 1 44 75 98 165 54 90 101 172 105 182 3 9 10
17 15 17 9 0 82 -280 82 -313 0 -23 7 -22 87 17 l70 34 -60 221 -61 221 -88 0
-88 0 -27 -42z m175 -5 c-1 -5 4 -20 10 -34 6 -15 14 -43 17 -63 4 -20 11 -34
15 -31 5 3 6 -4 3 -14 -3 -10 4 -45 16 -77 11 -33 23 -72 25 -89 3 -16 8 -40
12 -52 5 -18 0 -24 -27 -36 -33 -13 -35 -13 -48 12 -8 14 -16 37 -18 51 -5 42
-8 55 -15 65 -7 12 -21 81 -18 91 1 4 -2 10 -7 13 -5 3 -14 18 -20 33 -10 23
-10 29 5 40 21 16 29 78 10 79 -7 0 -4 4 7 9 26 11 35 12 33 3z m-100 -23 c3
-7 0 -10 -6 -6 -11 7 -11 -58 0 -69 4 -4 -4 -19 -18 -34 -14 -14 -34 -42 -45
-61 -11 -19 -28 -48 -39 -65 -10 -16 -27 -45 -36 -62 -9 -18 -21 -33 -27 -33
-6 0 -7 -3 -4 -6 4 -4 -2 -19 -12 -33 -24 -35 -51 -91 -51 -106 0 -7 8 -16 18
-20 16 -6 16 -7 -1 -17 -10 -5 -25 -7 -33 -4 -9 3 -12 1 -9 -4 12 -20 -63 -10
-86 11 -17 14 -19 19 -7 15 11 -3 15 -1 12 8 -3 7 11 35 31 62 19 27 38 56 42
64 3 8 16 29 27 45 12 17 34 55 50 85 16 30 37 64 47 76 11 11 19 25 19 30 0
6 16 29 36 52 19 23 32 42 27 42 -4 0 4 11 17 24 27 26 41 28 48 6z m44 -19
c3 -23 0 -30 -24 -39 -26 -10 -27 -9 -16 11 7 12 11 30 9 40 -1 9 4 17 12 17
9 0 17 -12 19 -29z"/>
<path d="M1575 1550 l-29 -181 -61 -33 c-321 -180 -626 -296 -773 -296 -68 0
-140 28 -183 71 l-40 40 -44 -38 c-25 -20 -45 -42 -45 -48 1 -16 66 -53 131
-75 122 -42 324 -17 525 65 49 20 157 71 241 113 83 42 264 134 402 204 432
220 539 262 712 280 130 13 203 1 281 -46 77 -46 112 -107 117 -204 4 -88 -11
-167 -46 -237 -38 -75 -124 -193 -187 -255 -55 -54 -65 -70 -46 -70 23 0 203
187 246 256 150 241 118 480 -79 585 -56 30 -71 33 -167 37 -106 4 -222 -12
-312 -44 -37 -13 -43 -12 -81 10 -62 36 -139 46 -347 46 l-186 0 -29 -180z
m244 133 c3 -2 -1 -9 -7 -15 -7 -7 -12 -20 -12 -30 0 -10 -3 -17 -7 -16 -18 3
-63 -15 -78 -32 -9 -10 -18 -29 -20 -42 -1 -12 -6 -34 -9 -48 -6 -20 -1 -30
19 -48 29 -26 29 -30 -1 -35 -11 -1 -33 -13 -48 -25 -16 -12 -33 -19 -38 -16
-6 4 -14 0 -19 -7 -19 -31 -18 5 1 117 12 66 24 134 27 151 7 41 19 51 68 55
40 4 116 -1 124 -9z m209 -3 c2 -5 10 -6 18 -3 14 5 104 -36 104 -47 0 -3 -21
-13 -48 -23 -26 -10 -55 -23 -64 -28 -10 -5 -25 -9 -33 -9 -8 0 -15 -4 -15
-10 0 -5 -5 -10 -11 -10 -5 0 -8 3 -5 8 7 12 -17 37 -51 51 -24 10 -33 20 -33
37 0 12 -5 26 -10 29 -6 4 -7 11 -4 17 8 12 147 1 152 -12z m620 -7 c23 -3 45
-10 48 -14 7 -12 -9 -11 -85 5 -35 8 -71 12 -80 9 -14 -4 -14 -3 -1 6 8 6 29
9 45 6 17 -3 49 -8 73 -12z m-788 -28 c0 -14 0 -28 1 -32 0 -5 -6 -9 -14 -11
-11 -2 -15 7 -15 32 0 24 5 36 14 36 8 0 14 -10 14 -25z m895 -31 c23 -25 32
-44 20 -44 -8 0 -55 51 -55 60 0 16 10 12 35 -16z m-808 -40 c-12 -13 -226
-112 -230 -108 -3 3 -1 17 4 32 5 15 11 39 14 55 l5 27 107 0 c58 0 104 -3
100 -6z m-377 -244 c0 -5 -16 -12 -35 -16 -19 -3 -38 -10 -41 -15 -15 -25
-134 -68 -134 -49 0 5 6 10 13 10 6 0 39 14 72 31 33 17 74 35 90 39 17 4 31
8 33 9 1 1 2 -3 2 -9z m-1035 -275 c28 -25 35 -35 23 -35 -10 0 -18 5 -18 11
0 6 -7 9 -15 5 -8 -3 -15 -2 -15 3 0 5 -9 11 -20 14 -22 6 -28 37 -7 37 6 -1
30 -16 52 -35z"/>
<path d="M447 1627 c-103 -110 -132 -149 -180 -237 -133 -249 -29 -490 235
-544 96 -20 174 -20 272 -2 98 19 276 79 345 116 29 16 58 27 62 24 5 -3 9
-17 9 -32 0 -15 6 -38 13 -52 12 -24 16 -25 92 -21 44 1 81 4 82 5 5 5 -46
157 -56 170 -10 12 -6 16 21 21 18 4 59 21 92 37 32 16 60 27 63 24 4 -4 -26
-194 -39 -244 -2 -11 38 -13 212 -12 297 1 373 23 486 135 107 105 151 228
142 392 -3 48 -8 95 -12 104 -6 14 4 17 76 23 111 10 198 -5 281 -48 80 -42
89 -30 13 19 -220 141 -355 111 -961 -212 -502 -267 -772 -370 -998 -380 -260
-12 -400 70 -422 248 -17 140 51 289 220 484 28 31 52 61 53 66 10 26 -22 -1
-101 -84z m1821 -157 c10 -124 4 -189 -23 -263 -37 -101 -44 -113 -86 -155
-55 -55 -68 -64 -81 -56 -7 4 -8 3 -4 -4 4 -7 -4 -16 -21 -23 -15 -6 -50 -22
-76 -35 -46 -23 -60 -25 -261 -30 -239 -6 -229 -9 -216 66 5 25 12 69 18 98 8
45 7 55 -10 74 -18 22 -18 22 4 34 13 7 27 9 32 4 5 -5 21 -3 38 6 17 9 27 18
24 21 -3 3 -17 -2 -31 -11 -14 -9 -25 -13 -25 -9 0 5 20 19 44 32 43 22 74 22
59 -2 -4 -7 -8 -16 -8 -22 -3 -41 -19 -112 -28 -123 -8 -10 -7 -16 2 -22 8 -4
11 -12 8 -17 -7 -11 66 -38 79 -31 5 3 38 2 74 -3 38 -4 69 -4 75 2 5 5 21 9
35 9 35 0 145 55 168 85 11 13 22 22 25 19 2 -3 14 14 26 38 12 24 26 50 30
58 4 8 12 24 17 35 14 28 18 202 5 209 -17 11 -23 -13 -25 -102 -1 -67 -7 -99
-25 -140 -28 -62 -110 -142 -167 -161 -40 -13 -262 -28 -287 -18 -11 4 -11 20
2 97 l16 91 50 25 c28 14 86 45 130 68 44 24 116 62 161 85 44 24 97 58 118
77 37 34 75 45 77 22 0 -7 4 -3 10 10 5 14 16 22 26 20 12 -2 17 -17 21 -58z
m-2008 -367 c8 -34 7 -36 -6 -19 -8 11 -14 31 -14 45 0 33 10 21 20 -26z
m1044 -74 c3 -17 13 -46 22 -65 24 -50 14 -64 -46 -64 -43 0 -50 3 -50 19 0
11 -4 23 -10 26 -5 3 -10 13 -10 22 0 9 -7 21 -17 28 -19 14 -10 35 15 35 9 0
28 7 42 14 38 21 47 19 54 -15z m-1013 9 c1 -7 1 -16 0 -20 0 -5 3 -8 8 -8 5
0 12 -9 16 -19 3 -11 15 -23 26 -26 10 -4 17 -11 13 -16 -3 -6 -21 6 -39 27
-34 38 -51 74 -34 74 5 0 10 -6 10 -12z m779 -77 c0 -10 -25 -21 -48 -21 -15
1 -14 3 2 15 22 17 46 20 46 6z m-595 -61 c71 -24 98 -46 45 -35 -19 4 -48 13
-64 21 -16 8 -35 14 -41 14 -7 0 -19 8 -26 17 -16 19 -17 19 86 -17z m365 2
c0 -7 -37 -25 -77 -37 -14 -4 -23 -2 -23 4 0 6 14 11 30 11 17 0 30 4 30 9 0
5 8 12 18 14 9 3 18 5 20 6 1 0 2 -2 2 -7z m-115 -42 c-8 -13 -115 -13 -135 0
-10 7 9 10 63 10 50 0 76 -4 72 -10z"/>
<path d="M1983 1193 c-15 -6 -17 -43 -3 -43 6 0 10 7 10 15 0 7 8 19 18 24 13
8 14 10 2 10 -8 0 -21 -3 -27 -6z"/>
<path d="M1812 972 c-7 -5 -10 -16 -6 -25 9 -25 24 -20 24 8 0 28 0 28 -18 17z"/>
<path d="M1863 955 c0 -15 5 -25 13 -25 8 0 14 10 14 25 0 15 -6 25 -14 25 -8
0 -13 -10 -13 -25z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
